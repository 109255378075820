<template>
  <div>
    <van-nav-bar title="" left-text="" right-text="" left-arrow @click-left="backShouye" />

    <van-popup v-model="show" closeable position="bottom" :style="{ height: '50%' }">
      <div class="popup-inner">
        <div class="title">确认支付</div>
        <div class="price color333">
          <span class="fz18">￥</span>
          <span class="price-num">129</span>
        </div>

        <van-radio-group v-model="payType">

          <van-cell>
            <template #title>
              <van-image width="24" height="24" src="/img/pay/weChat-icon.png"
                style="vertical-align: middle; margin-right: 6px" />
              <span style="line-height: 24px">微信</span>
            </template>
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell>
            <template #title>
              <van-image width="24" height="24" src="/img/pay/alipay-icon.png"
                style="vertical-align: middle; margin-right: 6px" />
              <span style="line-height: 24px">支付宝</span>
            </template>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-radio-group>

        <van-button type="info" class="bottom-btn" @click="pay">立即付款</van-button>
      </div>
    </van-popup>
    <!-- 成功、失败 -->
    <van-popup v-model="payStatus" closeable close-icon-position="top-left" position="bottom" @close="payStatus = false"
      :style="{ height: '50%' }">
      <div class="popup-success">
        <div class="title" v-if="success">支付成功</div>
        <div class="title" v-else>支付失败</div>
        <div class="price color333">
          <van-image width="80" height="80" :src="require('../img/success.png')" v-if="success" />
          <van-image width="80" height="80" :src="require('../img/error.png')" v-else />
        </div>
        <div class="tishi" v-if="success">恭喜您，支付成功！</div>
        <div class="tishi" v-else>支付失败,号码已被占有</div>


        <van-button type="info" class="bottom-back" @click="backShouye">返回首页</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { payorder } from "@/api/lianghao/index";
import { openPay } from '@/common'
export default {
  name: "orderPay",
  props: {

  },
  data() {
    return {
      id: '',
      show: true,//支付弹框
      payType: "1",//支付類型
      orderId: '',//上個頁面的參數
      // 成功失败
      payStatus: false,//成功失败弹框
      success: false, //判定支付成功还是失败
      // list:{},//传给原生的数据
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
    }

    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
      // console.log(this.$route.query.session);
    }
    // 获取路由参数 orderId
    if (this.$route.params.orderId) {
      this.orderId = this.$route.params.orderId;
    }
    // console.log('orderId:', this.orderId);
    window.paySuccess = this.paySuccess;
    window.payError = this.payError;

  },

  methods: {



    pay() {
      let data = {
        type: this.payType, orderId: this.orderId
      }
      // let handData = {}
      payorder(data).then(res => {
        // console.log(handData)
        // handData = res.data.data
        if (res.data.code === 0) {
          if (this.payType == 1) {
            // 微信
            openPay(res, '1')

          } else if (this.payType == 2) {
            // 支付宝
            openPay(res.data, '2')

          }
        }
      })


    },
    // 成功的函数
    paySuccess() {
      this.show = false
      this.payStatus = true
      this.success = true
    },
    // 失败的函数
    payError() {
      this.payStatus = true
      this.success = false
    },
    backShouye() {
      this.$router.replace(
        `lianghao?id=${this.id}`
      );
    }
  },
  watch: {
    // payShow() {
    //   this.show = this.payShow;
    // },
  },


};
</script>

<style lang="less" scoped>
.van-popup {
  box-sizing: border-box;
  border-radius: 20px 20px 0px 0px;

  .popup-success {
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    align-items: center;
    height: 100%;

    .bottom-back {
      width: 60%;
      height: 48px;
      background: linear-gradient(180deg, #A3C6FF 0%, #007AFF 100%);
      opacity: 1;
      border-radius: 24px;

    }

    .tishi {
      font-size: 18px;


      font-weight: 400;
      line-height: 0px;
      color: #333333;
      letter-spacing: 6px;
      opacity: 1;
    }

    .popup-success {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .popup-inner {
    // padding-top: 24px;
    padding: 24px;

    .title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
      margin-bottom: 40px;
    }

    .price {
      text-align: center;
      font-weight: 500;

      color: #3B99FF;

      .price-num {
        font-size: 28px;
      }
    }

    .bottom-btn {
      // position: absolute;
      // bottom: 40px;
      // width: 90%;
      // margin-left: 5%;
      margin-top: 20px;
      width: 100%;
      height: 48px;
      background: linear-gradient(360deg, #007AFF 0%, #A3C6FF 100%);
      border-radius: 24px;
    }


  }
}

// 下面三个控制弹出框左右两边的取消确认按钮
/deep/ .van-icon-cross:before {
  display: none;
}
</style>