import {fetchPost,fetchGet} from "@/router/https"

// 下单锁号
export function xiadansuohao(data){
    return fetchPost('/mallapp/phoneNumSelection/order',data)
}
// 查询联通号码列表
export function getList(param) {
  return fetchGet('/mallapp/phoneNumSelection/getList',param);
}

// 查询收货地址编码
export function searchAreaInfo (params) {
    return fetchGet('/mallapp/phoneNumSelection/address/areaInfo', params)
}
// 查询我的订单记录
export function searchDingdanInfo (params) {
  return fetchGet('/mallapp/phoneNumSelection/orderInfo', params)
}

// 订单支付
export function payorder (params) {
  return fetchPost('/mallapp/phoneNumSelection/orderPay', params)
}
// 购买靓号信息回显
export function getUserInfo (params) {
  return fetchGet('/mallapp/phoneNumSelection/getUserMsg', params)
}